import useGetMissionHistoryLog from "../query/useGetMissionHistoryLog"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"

const VEHICLE_MOTION_STATES = {
  "STOP-REQUEST-INITIATED": { value: 25, message: "Stop Request Sending", type: "stop", key: "STOP-REQUEST-INITIATED" },
  "STOP-REQUEST-SENT": { value: 99, message: "Stop Request Sent", type: "stop", key: "STOP-REQUEST-SENT" },
  "STOP-REQUEST-APPROVED": {
    value: 100,
    message: "Stop Request Accepted",
    type: "stop",
    key: "STOP-REQUEST-APPROVED",
    post_state_message: "SDT Stopped MRC Active",
    icon: <CheckCircleIcon sx={{ color: "#71bf5e" }} />,
  },
  "STOP-REQUEST-TIMEOUT": {
    value: 100,
    message: "Stop Request Timed out",
    error: true,
    type: "stop",
    key: "STOP-REQUEST-TIMEOUT",
    post_state_message: "Stop Request Timed out",
  },
  "STOP-REQUEST-REJECTED": {
    value: 100,
    message: "Stop Request Rejected",
    error: true,
    type: "stop",
    key: "STOP-REQUEST-REJECTED",
    post_state_message: "Stop Request Rejected",
    icon: <CancelIcon sx={{ color: "#fa3751" }} />,
  },
  "RESUME-REQUEST-INITIATED": {
    value: 25,
    message: "Resume Request Sending",
    type: "resume",
    key: "RESUME-REQUEST-INITIATED",
  },
  "RESUME-REQUEST-SENT": {
    value: 99,
    message: "Resume Request Sent",
    type: "resume",
    key: "RESUME-REQUEST-SENT",
  },
  "RESUME-REQUEST-APPROVED": {
    value: 100,
    message: "Resume Request Accepted",
    type: "resume",
    key: "RESUME-REQUEST-APPROVED",
    post_state_message: "Resume Request Complete",
    icon: <CheckCircleIcon sx={{ color: "#71bf5e" }} />,
  },
  "RESUME-REQUEST-TIMEOUT": {
    value: 100,
    message: "Resume Request Timed out",
    error: true,
    type: "resume",
    key: "RESUME-REQUEST-TIMEOUT",
    post_state_message: "Resume Request Timed out",
  },
  "RESUME-REQUEST-REJECTED": {
    value: 100,
    message: "Resume Request Rejected",
    error: true,
    type: "resume",
    key: "RESUME-REQUEST-REJECTED",
    post_state_message: "Stop Request Rejected",
    icon: <CancelIcon sx={{ color: "#fa3751" }} />,
  },
}

const VEHICLE_REROUTE_STATES = {
  "REROUTE-REQUEST-INITIATED": {
    value: 25,
    message: "Reroute Request Sending",
    type: "reroute",
    key: "REROUTE-REQUEST-INITIATED",
  },
  "REROUTE-REQUEST-SENT": {
    value: 99,
    message: "Reroute Request Sent",
    type: "reroute",
    key: "REROUTE-REQUEST-SENT",
  },
  "REROUTE-REQUEST-APPROVED": {
    value: 100,
    message: "Reroute Request Accepted",
    type: "reroute",
    key: "REROUTE-REQUEST-APPROVED",
    post_state_message: "Reroute Request Complete",
    icon: <CheckCircleIcon sx={{ color: "#71bf5e" }} />,
  },
  "REROUTE-REQUEST-TIMEOUT": {
    value: 100,
    message: "Reroute Request Timed out",
    error: true,
    type: "reroute",
    key: "REROUTE-REQUEST-TIMEOUT",
    post_state_message: "Reroute Request Timed out",
  },
  "REROUTE-REQUEST-REJECTED": {
    value: 100,
    message: "Reroute Request Rejected",
    error: true,
    type: "reroute",
    key: "REROUTE-REQUEST-REJECTED",
    post_state_message: "Reroute Request Rejected",
    icon: <CancelIcon sx={{ color: "#fa3751" }} />,
  },
}

const useInferredVehicleState = () => {
  const { data } = useGetMissionHistoryLog()

  const lastMotionLog = data?.find(
    (log) => VEHICLE_MOTION_STATES[log?.details?.result as keyof typeof VEHICLE_MOTION_STATES]
  )
  const lastRerouteLog = data?.find(
    (log) => VEHICLE_REROUTE_STATES[log?.details?.result as keyof typeof VEHICLE_REROUTE_STATES]
  )

  return {
    motionState: lastMotionLog
      ? VEHICLE_MOTION_STATES[lastMotionLog?.details?.result as keyof typeof VEHICLE_MOTION_STATES]
      : null,
    rerouteState: lastRerouteLog
      ? VEHICLE_REROUTE_STATES[lastRerouteLog?.details?.result as keyof typeof VEHICLE_REROUTE_STATES]
      : null,
    motionLog: lastMotionLog,
    rerouteLog: lastRerouteLog,
  }
}

export { VEHICLE_MOTION_STATES, VEHICLE_REROUTE_STATES }

export default useInferredVehicleState
