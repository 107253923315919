import { useMutation, useQueryClient } from "@tanstack/react-query"
import execute from "../api/SessionsAPIGraphQL"
import { RefreshSessionDocument } from "@torc-robotics/vda-sessions-api/graphql/document"
import { useEffect } from "react"
import { getLogger } from "../../framework/contexts/DataDog"
import useCurrentSession from "../hooks/useCurrentSession"

const logger = getLogger()

export const useRefreshSessionMutation = () => {
  const queryClient = useQueryClient()

  const session = useCurrentSession()

  const mutation = useMutation({
    mutationFn: async () => {
      return await execute(RefreshSessionDocument, { sessionId: session?.sessionId ?? "" })
    },
    onSuccess: /* istanbul ignore next */ async ({ data }) => {
      if (
        data?.refreshSession.__typename === "SessionNotFoundError" ||
        data?.refreshSession.__typename === "UserNotAuthorizedError"
      ) {
        logger.error(data.refreshSession.message)
      } else if (data?.refreshSession.__typename === "ActiveSession") {
        await queryClient.invalidateQueries({
          queryKey: ["getSessions"],
        })
      } else {
        logger.warn("Unhandled return type for RefreshSessionMutation: ", data?.refreshSession)
      }
    },
    onError: (error) => {
      logger.error("useRefreshSessionMutation", {}, error)
    },
  })

  useEffect(() => {
    const interval = setInterval(
      /* istanbul ignore next */ () => {
        mutation.mutate()
      },
      3000
    )

    return () => clearInterval(interval)
  }, [])

  return mutation
}

export default useRefreshSessionMutation
