import { useAuthenticator } from "@aws-amplify/ui-react"
import { Auth } from "aws-amplify"
import { useEffect, useState } from "react"

/**
 * Fetches geo credentials from AWS
 */
export const useGeoCreds = () => {
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus])
  const [geoCreds, setGeoCreds] = useState()
  useEffect(() => {
    if (authStatus === "authenticated") {
      Auth.currentUserCredentials().then((result) => {
        setGeoCreds(result)
      })
    }
    return () => {
      setGeoCreds() // Clean on unmount to prevent memory leaks
    }
  }, [authStatus, user])
  return geoCreds
}
