import "./DragHandle.scss"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"

const DragHandle = () => {
  return (
    <div className="swapy-drag-handle">
      <div className="swapy-drag-handle__icon" data-swapy-handle>
        <DragIndicatorIcon sx={{ transform: "rotate(90deg)" }} />
      </div>
    </div>
  )
}

export default DragHandle
