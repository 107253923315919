/* istanbul ignore file */
import { MissionsApiClient } from "@torc-robotics/mm-missions-client"
import { Auth } from "aws-amplify"

const MissionsAPI = new MissionsApiClient({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  BASE: import.meta.env.VITE_MISSIONS_API_URL,
  TOKEN: async () => await Auth.currentSession().then((res) => res.getIdToken().getJwtToken()),
})

export default MissionsAPI
