import "./scss/eventLog.scss"

const EventLog = () => {
  return (
    <div className="event-log-container" data-testid="event-log-container">
      event log stuff goes here
    </div>
  )
}

export default EventLog
