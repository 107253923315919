import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, IconButton, Typography } from "@mui/material"
import { RemoteAssistanceEvent } from "@torc-robotics/vda-remote-assistance-api/graphql/document"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import useStartSessionMutation from "@/framework/query/useStartSessionMutation"
import useBrowserId from "@/framework/hooks/useBrowserId"
import useElapsedTime from "@/framework/hooks/useElapsedTime"
import { formatAssistanceRequestDisplay, formatLatLon, formatTimeSinceEpoch } from "@/framework/utils/Utils"
import "./AssistanceQueueListItem.scss"
import useVehicleStore from "@/framework/store/useVehicleStore"
import { useEffect, useRef } from "react"
import usePerformanceStore from "@/framework/store/usePerformanceStore"

const EXPAND_ANIMATION_DURATION = 200

const AssistanceQueueListItem = ({
  event,
  expandedLoadId,
  handleChange,
}: {
  event: RemoteAssistanceEvent
  expandedLoadId: string | false
  handleChange: (loadId: string) => void
}) => {
  const performance = usePerformanceStore()
  const vehicleStatus = useVehicleStore((state) => state.vehicles.entities[event.vehicleId] ?? null)
  const browserId = useBrowserId()
  const { mutate } = useStartSessionMutation()

  const expanded = expandedLoadId === event.loadId
  const expandedRef = useRef(expanded)
  const cardRef = useRef<HTMLDivElement>(null)

  const { vehicleId, loadId } = event
  const elapsedTime = useElapsedTime(Math.floor(event.creationTs / 1000))

  useEffect(() => {
    const scrollableDiv = document.getElementById("assistance-queue-list")

    if (!scrollableDiv) {
      return
    }

    setTimeout(() => {
      const cardRect = cardRef.current?.getBoundingClientRect() as DOMRect
      const scrollableRect = scrollableDiv.getBoundingClientRect()

      if (expanded && !expandedRef.current) {
        const scrollTop =
          cardRect?.top + scrollableDiv.scrollTop - scrollableRect.top - (scrollableRect.height - cardRect.height) / 2

        scrollableDiv?.scrollTo({ top: scrollTop, behavior: "smooth" })
      }

      expandedRef.current = expanded
    }, EXPAND_ANIMATION_DURATION)
  }, [expanded])

  return (
    <Accordion
      expanded={expanded}
      slotProps={{ transition: { unmountOnExit: true, timeout: EXPAND_ANIMATION_DURATION } }}
      disableGutters
      onChange={handleChange(event.loadId)}
      ref={cardRef}
    >
      <AccordionSummary
        sx={{ pointerEvents: "none" }}
        expandIcon={
          <IconButton sx={{ pointerEvents: "auto", color: "#333333" }}>
            <ExpandMoreIcon />
          </IconButton>
        }
        aria-controls={`content-${event.loadId}`}
        id={`header-${event.loadId}`}
      >
        <Typography noWrap className="assistance-queue__queue-header--vehicle-id">
          {event.vehicleId}
        </Typography>
        <Typography className="assistance-queue__queue-header--timestamp">
          {formatTimeSinceEpoch(elapsedTime)}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className="assist-button"
          sx={{ pointerEvents: "auto" }}
          onClick={(e) => {
            mutate({ browserId, loadId, vehicleId })
            e.stopPropagation()
            performance.startTiming("startSessionToAssistView")
          }}
        >
          Assist
        </Button>
      </AccordionSummary>
      <AccordionDetails className="assistance-queue__queue-details">
        <Typography component={"span"} className="assistance-queue__queue-details--request-type">
          Assist Request: <Chip size="small" label={formatAssistanceRequestDisplay(event.eventType)} />
        </Typography>
        <Typography className="assistance-queue__queue-details--request-description">
          Mission ID: <span>{event.loadId}</span>
        </Typography>
        <Typography className="assistance-queue__queue-details--request-description" noWrap>
          Location:{" "}
          <span>
            {vehicleStatus?.data?.coordinates
              ? formatLatLon(vehicleStatus.data.coordinates.latitude, vehicleStatus.data.coordinates.longitude)
              : ""}
          </span>
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default AssistanceQueueListItem
