import "./scss/remoteAssistance.scss"
import MapPanel from "../map-panel/MapPanel"
import VDHLPanel from "../virtual-driver-behavior-log/VDHLPanel"
import EndSessionButton from "../end-session/EndSessionButton"
import useMissionQuery from "@/framework/query/useMissionQuery"
import LoadScreen from "@/framework/utils/LoadScreen"
import useVehicleDetailsQuery from "@/framework/query/useVehicleDetailsQuery"
import { useEffect, useRef, useState } from "react"
import { createSwapy } from "swapy"
import usePerformanceStore from "@/framework/store/usePerformanceStore"
import useCurrentSession from "@/framework/hooks/useCurrentSession"
import { Tab, Tabs, Typography } from "@mui/material"
import { tabSettings, defaultTab } from "./tabHelper"

const RemoteAssistance = () => {
  const performance = usePerformanceStore()
  const { data: missionsData, isLoading: missionIsLoading, isError: missionIsError } = useMissionQuery()
  const { data: vehicleData, isLoading: vehicleIsLoading, isError: vehicleIsError } = useVehicleDetailsQuery()
  const session = useCurrentSession()

  const missionIsReady = !missionIsLoading
  const missionIsValid =
    missionIsReady &&
    !missionIsError &&
    missionsData?.missions?.length === 1 &&
    missionsData?.missions[0].state == "IN_PROGRESS"

  const vehicleIsReady = !vehicleIsLoading
  const vehicleIsFound = vehicleIsReady && !vehicleIsError
  const vehicleIsValid = vehicleIsFound && !!vehicleData?.gen?.startsWith("3")

  const isReady = missionIsValid && vehicleIsValid

  /**
   * Get the item by id
   * @param {"remote-viz" | "remote-cam" | "remote-map" | null} itemId
   * @returns {JSX.Element}
   */
  const getItemById = (itemId) => {
    switch (itemId) {
      case "remote-map":
        return <MapPanel />
    }
  }

  const [isDragging, setIsDragging] = useState(false)

  const swapyRef = useRef(null)
  useEffect(() => {
    if (!isReady) return

    if (swapyRef.current) {
      /* istanbul ignore next */
      swapyRef.current.destroy()
    }

    const container = document.getElementById("remote-assistance")
    const swapy = createSwapy(container, {
      swapMode: "drop",
      animation: "dynamic",
    })
    swapyRef.current = swapy

    swapy.onSwapStart(
      /* istanbul ignore next */ () => {
        setIsDragging(true)
      }
    )

    swapy.onSwapEnd(
      /* istanbul ignore next */ () => {
        setIsDragging(false)
      }
    )

    return () => {
      swapy.destroy()
    }
  }, [isReady, session.sessionId])

  useEffect(() => {
    performance.endTiming("startSessionToAssistView")
  }, [])

  const [tab, setTab] = useState(defaultTab)

  const handleSelectedTab = (event, tab) => {
    setTab(tab)
  }

  return (
    <div key={session.sessionId}>
      <div
        id="remote-assistance"
        data-testid="remote-assistance"
        className="remote-assistance-container"
        data-swapy-is-dragging={isDragging}
      >
        {!missionIsReady || !vehicleIsReady ? (
          <LoadScreen />
        ) : !isReady ? (
          <>
            {!vehicleIsValid && <Typography variant="body1">There was an issue retrieving vehicle data.</Typography>}
            {!missionIsValid && <Typography variant="body1">There was an issue retrieving mission data.</Typography>}
          </>
        ) : (
          <>
            <Tabs value={tab} onChange={handleSelectedTab} className="remote-assistance-container__tabs">
              {tabSettings.map((tabSetting) => (
                <Tab
                  key={tabSetting.label}
                  label={tabSetting.label}
                  value={tabSetting.selectedTab}
                  data-testid={tabSetting.label}
                  disabled={tabSetting.disable}
                />
              ))}
            </Tabs>
            <div className="remote-assistance-container__tab-panel">
              {tabSettings.find((tabSetting) => tabSetting.selectedTab === tab).element}
            </div>
            <div className="remote-assistance-container__map-panel" data-swapy-slot="B">
              {getItemById("remote-map")}
            </div>
            <div className="remote-assistance-container__vdhl">
              <VDHLPanel />
            </div>
            <div className="remote-assistance-container__end-session">
              <EndSessionButton />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default RemoteAssistance
