/* eslint-disable @typescript-eslint/indent */
import config from "@/config"
import useMqttStore from "@/framework/store/useMqttStore"
import { useAuthenticator } from "@aws-amplify/ui-react"
import { isEmpty } from "lodash"
import { useEffect, useMemo, useRef } from "react"
import { getLogger } from "../../framework/contexts/DataDog"

const logger = getLogger()

interface SubscriptionOptions<T> {
  /** the topic(s) to subscribe to */
  topic: string | string[]
  /** the id of the subscription */
  id?: string
  /** the callback to run when a message is received */
  // next?: (message: unknown) => void
  /** the callback on error */
  error?: (error: Error) => void
  /** the callback on complete */
  complete?: () => void
  /** the callback to run when a batched message is received */
  // batchNext?: (prev: Record<string, unknown>, message: T) => unknown
  /** the callback to run when a batch of messages is complete */
  // batchComplete?: (batch: Record<string, Message<T>>) => void
  /** how frequently to flush a batch (ms) */
  // batchInterval?: number
  /** the initial data to seed the batch with */
  batchInitialData?: unknown
  /** whether or not to enable the subscription */
  enabled?: boolean
}

type EnforceSubscriptionOptions<T> =
  | (SubscriptionOptions<T> & {
      next: (message: T) => void
      batchNext?: never
      batchInterval?: never
      batchComplete?: never
    })
  | (SubscriptionOptions<T> & {
      next?: undefined
      batchNext: (prev: unknown, message: T) => unknown
      batchInterval: number
      batchComplete?: (batch: unknown) => void
    })

interface SubscriptionReturn {
  /** lets you know when the subscription is active */
  isActive: boolean
  /** subscribe to the topic */
  subscribe: () => void
  /** unsubscribe from the topic */
  unsubscribe: () => void
  /** cleanup the subscription */
  cleanup: () => void
}

// this code is still garbage, don't look at it
const useMqttSubscription = <T>({
  topic,
  id,
  next,
  error = (e) => {
    logger.error("error", e)
  },
  complete = () => {
    logger.debug("complete")
  },
  batchNext,
  batchComplete,
  batchInterval,
  batchInitialData = {},
  enabled = true,
}: EnforceSubscriptionOptions<T>): SubscriptionReturn => {
  const idMemo = useMemo(() => {
    if (id != null) {
      return id
    } else if (typeof topic === "string") {
      return topic
    } else {
      return topic.sort().join(",")
    }
  }, [id, topic])

  const { user, authStatus } = useAuthenticator((ctx) => [ctx.user, ctx.authStatus])
  const client = useMqttStore((state) => state.client)
  const connect = useMqttStore((state) => state.connect)
  const createSubscription = useMqttStore((state) => state.createSubscription)

  // setup client on auth
  useEffect(() => {
    if (authStatus === "authenticated" && user !== null && client === null) {
      const accessToken = user?.getSignInUserSession()?.getAccessToken().getJwtToken()
      connect({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        aws_pubsub_endpoint: `wss://${config.VITE_IOT_MQTT_HOST}/mqtt?x-amz-customauthorizer-name=${config.IOT_AUTHORIZER_NAME}&access_token=${accessToken}`,
      })
    }
  }, [authStatus, user, client])

  // create subscription
  useEffect(() => {
    if (batchInterval == null && batchNext == null && next == null) {
      throw new Error("next must be defined when batchInterval and batchNext are undefined")
    }
    if (batchInterval != null && batchNext == null) {
      throw new Error("batchNext must be defined when batchInterval is defined")
    }

    let nextFn
    if (batchInterval != null && batchNext != null) {
      nextFn = (message: T) => {
        batchUpdates.current = batchNext(batchUpdates.current, message)
      }
    } else {
      nextFn = next
    }

    if (enabled && client !== null) {
      createSubscription<T>({
        id: idMemo,
        topic,
        next: nextFn,
        error,
        complete,
      })
    }
  }, [client, enabled])

  const batchUpdates = useRef(batchInitialData)
  let batchTimeout: NodeJS.Timeout

  useEffect(() => {
    if (batchInterval != null && batchNext != null) {
      batchTimeout = setInterval(() => {
        if (isEmpty(batchUpdates.current)) return
        batchComplete?.(batchUpdates.current)
        batchUpdates.current = {}
      }, batchInterval)
    }
    return () => {
      clearTimeout(batchTimeout)
    }
  }, [])

  // subscribe / unsub on mount/unmount
  const subscription = useMqttStore((state) => state?.subscriptions[idMemo])
  const subscriptionRef = useRef<undefined | ZenObservable.Subscription>(subscription?.subscription)
  const subscribe = useMqttStore((state) => state.subscribe)
  const unsubscribe = useMqttStore((state) => state.unsubscribe)
  const cleanupSubscription = useMqttStore((state) => state.cleanupSubscription)
  useEffect(() => {
    subscriptionRef.current = subscription?.subscription
  }, [subscription?.subscription])

  useEffect(() => {
    if (enabled && client !== null && subscriptionRef.current === undefined) {
      subscribe(idMemo)
    }
  }, [client, enabled])

  // TODO - write functionality for 'enabled' change to true/false

  return {
    subscribe: () => {
      subscribe(idMemo)
    },
    unsubscribe: () => {
      unsubscribe(idMemo)
    },
    cleanup: () => {
      cleanupSubscription(idMemo)
    },
    isActive: subscription?.subscription !== undefined,
  }
}

export default useMqttSubscription
