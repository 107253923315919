import { type Map } from "maplibre-gl"
import { createRef } from "react"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import type { StateCreator } from "zustand"

export enum MapType {
  REMOTE_VIZ = "remote-viz",
  MONITOR_QUEUE = "monitor-queue",
  ASSISTANCE_QUEUE = "assistance-queue",
}

export interface HoverInfo<T> {
  x: number
  y: number
  object: T
  component: React.FC<{ object: T }>
}

interface RouteInfo {
  routeId: string
  estimated_time_minutes: number
  length_miles: number
}

interface HoverRoute extends RouteInfo {
  summaryInfo: {
    estimatedTimeMinutes: number
    lengthMiles: number
  }
}

interface BaseMap {
  mapRef: React.RefObject<Map>
  isLoaded: boolean
  isCentered: boolean
  setLoaded: () => void
  setCentered: (centered: boolean) => void
}

interface RemoteVizMap extends BaseMap {
  hoverRoute: HoverRoute | null
  selectedRoute: RouteInfo | null
  setHoverRoute: (hoverRoute: HoverRoute | null) => void
  setSelectedRoute: (selectedRoute: RouteInfo | null) => void
}

interface RemoteAssistanceMap extends BaseMap {
  hoverInfo: HoverInfo<unknown> | null
  containerSize: { width: number; height: number }
  setHoverInfo: <T>(hoverInfo: HoverInfo<T> | null) => void
  setContainerSize: (containerSize: { width: number; height: number }) => void
}

interface MapStore {
  [MapType.REMOTE_VIZ]: RemoteVizMap
  [MapType.MONITOR_QUEUE]: RemoteAssistanceMap
  [MapType.ASSISTANCE_QUEUE]: RemoteAssistanceMap
}

const createRemoteVizMapState = (set: StateCreator<MapStore, [], [], MapStore>): RemoteVizMap => ({
  mapRef: createRef<Map>(),
  isLoaded: false,
  isCentered: true,
  hoverRoute: null,
  selectedRoute: null,
  setLoaded: () => {
    set((state: MapStore) => ({
      [MapType.REMOTE_VIZ]: {
        ...state[MapType.REMOTE_VIZ],
        isLoaded: true,
      },
    }))
  },
  setCentered: (centered: boolean) => {
    set((state: MapStore) => ({
      [MapType.REMOTE_VIZ]: {
        ...state[MapType.REMOTE_VIZ],
        isCentered: centered,
      },
    }))
  },
  setHoverRoute: (hoverRoute) => {
    set((state: MapStore) => ({
      [MapType.REMOTE_VIZ]: {
        ...state[MapType.REMOTE_VIZ],
        hoverRoute,
      },
    }))
  },
  setSelectedRoute: (selectedRoute) => {
    set((state: MapStore) => ({
      [MapType.REMOTE_VIZ]: {
        ...state[MapType.REMOTE_VIZ],
        selectedRoute,
      },
    }))
  },
})

type RAMapKeys = {
  [K in keyof MapStore]: MapStore[K] extends RemoteAssistanceMap ? K : never
}[keyof MapStore]

const createRemoteAssistanceMapState = (
  mapKey: RAMapKeys,
  set: StateCreator<MapStore, [], [], MapStore>
): RemoteAssistanceMap => ({
  mapRef: createRef<Map>(),
  isLoaded: false,
  isCentered: true,
  hoverInfo: null,
  containerSize: { width: 0, height: 0 },
  setLoaded: () => {
    set((state: MapStore) => ({
      [mapKey]: {
        ...state[mapKey],
        isLoaded: true,
      },
    }))
  },
  setCentered: (centered: boolean) => {
    set((state: MapStore) => ({
      [mapKey]: {
        ...state[mapKey],
        isCentered: centered,
      },
    }))
  },
  setHoverInfo: (hoverInfo) => {
    set((state: MapStore) => ({
      [mapKey]: {
        ...state[mapKey],
        hoverInfo,
      },
    }))
  },
  setContainerSize: (containerSize) => {
    set((state: MapStore) => ({
      [mapKey]: {
        ...state[mapKey],
        containerSize,
      },
    }))
  },
})

const useMapStore = create<MapStore>()(
  devtools(
    (set) => ({
      [MapType.REMOTE_VIZ]: createRemoteVizMapState(set),
      [MapType.MONITOR_QUEUE]: createRemoteAssistanceMapState(MapType.MONITOR_QUEUE, set),
      [MapType.ASSISTANCE_QUEUE]: createRemoteAssistanceMapState(MapType.ASSISTANCE_QUEUE, set),
    }),
    { name: "Map Store" }
  )
)

export default useMapStore
