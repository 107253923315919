import useGetSessions from "@/framework/query/useGetSessions"
import useGlobalMqttSubscriptions from "@/framework/subscriptions/useGlobalMqttSubscriptions"
import useRemoteAssistanceEventSubscriptions from "@/framework/subscriptions/useRemoteAssistanceEventSubscriptions"
import useSessionSubscriptions from "@/framework/subscriptions/useSessionSubscriptions"
import { useState } from "react"
import { Outlet } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

type BrowserContextType = {
  browserId: string
}

const RemoteLayout = () => {
  useGetSessions()
  useSessionSubscriptions()
  useGlobalMqttSubscriptions()
  useRemoteAssistanceEventSubscriptions()

  const browserId = useState<string>(uuidv4())[0]

  return <Outlet context={{ browserId } satisfies BrowserContextType} />
}

export default RemoteLayout

export type { BrowserContextType }
