import { Box, Typography } from "@mui/material"
import useGetMissionHistoryLog from "@/framework/query/useGetMissionHistoryLog"
import useMissionQuery from "@/framework/query/useMissionQuery"
import LoadScreen from "@/framework/utils/LoadScreen"
import { getFormattedTime } from "@/framework/utils/Utils"
import { HorizontalRule } from "@mui/icons-material"

import "./scss/vdhl.scss"

const VDHLPanel = () => {
  const mission = useMissionQuery()?.data?.missions?.[0] ?? {}
  const { data, isLoading } = useGetMissionHistoryLog()

  return (
    <Box className="vdhl-panel" data-testid="vdhl-panel-container">
      <div className="vdhl-panel__header">
        <Typography>Virtual Driver Behavior Log</Typography>
      </div>
      {isLoading ? (
        <LoadScreen />
      ) : (
        <Box className="vdhl-panel__body">
          {data.length <= 0 || mission?.state !== "IN_PROGRESS" ? (
            <Typography data-testid="vdhl-no-logs" className="vdhl-panel__body__nologs">
              No Logs Found for Mission
            </Typography>
          ) : (
            data.map((log, index) => (
              <div data-testid={`vdhl-log-${index}`} className="vdhl-panel__body__log" key={index}>
                <div className="vdhl-panel__body__log__bullet">
                  <HorizontalRule />
                </div>
                <Typography className="vdhl-panel__body__log__title">
                  {log.title !== "State Transition"
                    ? log.details.result || log.description.toUpperCase()
                    : "In Transit"}
                </Typography>
                <div className="vdhl-panel__body__log__ellipsis"></div>
                <Typography className="vdhl-panel__body__log__date">
                  {getFormattedTime(log.created, null, "HH:mm:ss", null)}
                </Typography>
              </div>
            ))
          )}
        </Box>
      )}
    </Box>
  )
}

export default VDHLPanel
