import { Box, Button, Card, CardContent, CardHeader, Collapse, Grid, IconButton, Typography } from "@mui/material"
import { useState } from "react"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { ActiveSession } from "@torc-robotics/vda-sessions-api/graphql/document"
import { formatUserName, formatTimeSinceEpoch } from "@/framework/utils/Utils"
import "./scss/sessionCard.scss"
import useBrowserId from "@/framework/hooks/useBrowserId"
import useStartSessionMutation from "@/framework/query/useStartSessionMutation"
import useElapsedTime from "@/framework/hooks/useElapsedTime"

interface SessionCardProps {
  session: ActiveSession
  assistable: boolean
}

const SessionCard = ({ session, assistable }: SessionCardProps) => {
  const browserId = useBrowserId()
  const startSessionMutation = useStartSessionMutation()
  const [expanded, setExpanded] = useState(false)
  const elapsedTime = useElapsedTime(session.createdTs)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const checkoutSession = () => {
    startSessionMutation.mutate({ loadId: session.loadId, vehicleId: session.vehicleId, browserId })
  }

  return (
    <Card className="session-card">
      <CardHeader
        sx={{ padding: 0 }}
        title={
          <Box className="session-card-header">
            <Typography component="div" className="vehicle-id">
              {session.vehicleId}
            </Typography>
            <Box className="session-card-actions">
              {assistable && (
                <Button variant="contained" color="primary" className="assist-button" onClick={checkoutSession}>
                  Assist
                </Button>
              )}
              <IconButton onClick={handleExpandClick}>
                {expanded ? <ExpandLessIcon className="expand-button" /> : <ExpandMoreIcon className="expand-button" />}
              </IconButton>
            </Box>
          </Box>
        }
      />
      <CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box className="session-card-details">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className="session-card-attribute-label">RA:</Typography>
                <Typography className="session-card-attribute-value">{formatUserName(session.userName)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className="session-card-attribute-label">Assist ID:</Typography>
                <Typography className="session-card-attribute-value">{session.sessionId}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className="session-card-attribute-label">Time on hold:</Typography>
                <Typography className="session-card-attribute-value">{formatTimeSinceEpoch(elapsedTime)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className="session-card-attribute-label">Mission ID:</Typography>
                <Typography className="session-card-attribute-value">{session.loadId}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  )
}

export default SessionCard
