import { useMutation, useQueryClient } from "@tanstack/react-query"
import execute from "../api/SessionsAPIGraphQL"
import { StartSessionDocument } from "@torc-robotics/vda-sessions-api/graphql/document"
import useNavigateToMissionSession from "../hooks/useNavigateToMissionSession"
import { getLogger } from "../../framework/contexts/DataDog"
import { datadogRum } from "@datadog/browser-rum"

const logger = getLogger()

const useStartSessionMutation = () => {
  const navigateToMissionSession = useNavigateToMissionSession()
  const queryClient = useQueryClient()

  const sessionMutation = useMutation({
    mutationFn: async ({ loadId, vehicleId, browserId }: { loadId: string; vehicleId: string; browserId: string }) =>
      await execute(StartSessionDocument, { browserId, loadId, vehicleId }),
    onSuccess: /* istanbul ignore next */ async ({ data }) => {
      if (data?.startSession.__typename === "SessionExistsError") {
        logger.error(data.startSession.message)
        alert(data.startSession.message)
      } else if (data?.startSession.__typename === "ActiveSession") {
        await queryClient.invalidateQueries({
          queryKey: ["getSessions"],
        })
        navigateToMissionSession(data?.startSession?.loadId ?? "")
      } else {
        logger.warn("Unhandled return type for StartSessionMutation: ", data?.startSession)
      }
    },
    onError: (error) => {
      logger.error("Error starting session: ", error)
      alert(error)
    },
    onSettled(data, error, variables, context) {
      datadogRum.addAction("Starting Session", { data: data, variables: variables, error: error })
    },
    retry: false,
  })

  return sessionMutation
}

export default useStartSessionMutation
