import useMapStore, { MapType } from "../store/useMapStore"
import { useEffect, useRef } from "react"
import bbox from "@turf/bbox"
import useRerouteHandler from "./useRerouteHandler"
import useMissionsRouteGeoJson from "./useMissionsRouteGeoJson"

/**
 * useMapViewHandler - handles all aspects of centering the map on a route
 */
const useMapViewHandler = (mapType: MapType) => {
  const mapRef = useMapStore((state) => state[mapType].mapRef.current)
  const mapLoaded = useMapStore((state) => state[mapType].isLoaded)

  const boundsRef = useRef<number[][] | null>(null)

  const routeData = useMissionsRouteGeoJson()

  const { routeAdvisorRoutesQuery, isRerouting } = useRerouteHandler()
  const reroutePoints = routeAdvisorRoutesQuery
    .flatMap((query) => query.data?.latlons ?? [])
    .map((latlon) => [latlon.lon, latlon.lat])

  const points = reroutePoints.length > 0 ? reroutePoints : routeData?.geometry.coordinates ?? []

  useEffect(() => {
    const boundingBox = bbox({
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: points,
      },
      properties: [],
    })
    const mapBounds = [
      [boundingBox[0], boundingBox[1]],
      [boundingBox[2], boundingBox[3]],
    ]
    const isValidBounds = mapBounds.every((coord) => {
      const latitude = coord[1]
      return latitude >= -90 && latitude <= 90
    })
    if (!isValidBounds) {
      return
    }
    if ((boundsRef.current?.toString() !== mapBounds.toString() && mapLoaded) || isRerouting) {
      /* istanbul ignore next */
      setTimeout(() => {
        // @ts-expect-error: api response doesn't match types
        mapRef?.fitBounds(mapBounds, { padding: 50, animate: false })
        boundsRef.current = mapBounds
      }, 50)
    }
  }, [points, mapLoaded, isRerouting])
}

export default useMapViewHandler
