import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Authenticator } from "@aws-amplify/ui-react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import RemoteLayout from "@/features/layouts/RemoteLayout"
import { Auth } from "aws-amplify"
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from "@apollo/client"
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link"

import "../../assets/scss/Roboto-Regular.ttf"
import "../../assets/scss/Roboto-Medium.ttf"
import "../../assets/scss/Roboto-Bold.ttf"
import "./App.scss"
import ErrorBoundary from "../error/ErrorBoundary"
import RemoteRoot from "../remote-root/RemoteRoot"

const queryClient = new QueryClient()

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <RemoteLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          index: true,
          element: <RemoteRoot />,
        },
      ],
    },
  ],
  {
    basename: "/remote",
  }
)

const sessionsSubscriptionLink = createSubscriptionHandshakeLink({
  url: import.meta.env.VITE_SESSIONS_API_GRAPHQL_URL,
  auth: {
    type: "AWS_LAMBDA",
    token: /* istanbul ignore next */ async () => {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken()
      return `Bearer ${token}`
    },
  },
})

const remoteAssistanceSubscriptionLink = createSubscriptionHandshakeLink({
  url: import.meta.env.VITE_REMOTE_ASSISTANCE_API_GRAPHQL_URL,
  auth: {
    type: "AWS_LAMBDA",

    token: /* istanbul ignore next */ async () => {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken()
      return `Bearer ${token}`
    },
  },
})

const link = ApolloLink.split(
  /* istanbul ignore next */ (operation) => operation.getContext().clientName === "sessions",
  sessionsSubscriptionLink,
  remoteAssistanceSubscriptionLink
)

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apolloClient}>
        <RouterProvider router={router} />
      </ApolloProvider>
    </QueryClientProvider>
  )
}

// eslint-disable-next-line react/display-name
export default () => (
  <Authenticator.Provider>
    <App />
  </Authenticator.Provider>
)
