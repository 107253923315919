import { Button, Typography } from "@mui/material"
import "./scss/endSession.scss"
import { useCallback } from "react"
import useEndSessionMutation from "@/framework/query/useEndSessionMutation"
import useCurrentSession from "@/framework/hooks/useCurrentSession"
import { RemotePage, usePage } from "@/framework/contexts/Page"
import usePerformanceStore from "@/framework/store/usePerformanceStore"

const EndSessionButton = () => {
  const session = useCurrentSession()
  const endSessionMutation = useEndSessionMutation()
  const page = usePage()
  const performance = usePerformanceStore()

  const endSessionHandler = useCallback(() => {
    performance.startTiming("endSessionToDashboard")

    if (session) {
      endSessionMutation.mutate()
    }
    page.setActive(RemotePage.Dashboard)
    return
  }, [session?.sessionId])

  return (
    <Button className="end-session-button" data-testid="end-session-button" onClick={endSessionHandler}>
      <Typography variant="h7">Resolve Assistance</Typography>
    </Button>
  )
}

export default EndSessionButton
