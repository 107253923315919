import { useQuery } from "@tanstack/react-query"
import MissionsAPI from "../api/MissionsAPI"

const useMissionRouteProgressQuery = (missionUUID: string) => {
  const missionRouteQuery = useQuery({
    queryKey: ["selected-mission-route", missionUUID],
    queryFn: async () => await MissionsAPI.v1.getMissionRoutes({ missionUuid: missionUUID, selected: "true" }),
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
    enabled: !!missionUUID,
  })

  const routeUUID = missionRouteQuery.data?.[0]?.uuid ?? ""

  const missionRouteProgressQuery = useQuery({
    queryKey: ["mission-progress", missionUUID, routeUUID],
    queryFn: /* istanbul ignore next */ async () =>
      await MissionsAPI.v1.getMissionRouteProgress({ missionUuid: missionUUID, routeUuid: routeUUID }),
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
    enabled: !!routeUUID,
  })

  return missionRouteProgressQuery
}

export default useMissionRouteProgressQuery
