import { Box, Typography } from "@mui/material"
import { formatCalculatedETA, calculateTimeRemaining } from "../../framework/utils/Utils"
import useMissionQuery from "@/framework/query/useMissionQuery"

const MapRouteProgressDisplay = () => {
  const missionsQuery = useMissionQuery()

  const mission = missionsQuery?.data?.missions?.[0]

  return (
    <Box className="route-progress-display" textAlign={"center"}>
      <Typography variant="subtitle2">Route Status:</Typography>
      <Typography variant="subtitle2" className={"eta-line"} data-testid={"eta"}>
        <Typography variant="subtitle2">ETA:</Typography>
        <Typography variant="subtitle2" className={"eta"}>
          {formatCalculatedETA(mission?.eta_remaining_seconds)}
        </Typography>
      </Typography>
      <Typography variant="subtitle2" className={"time-left-line"} data-testid={"time-left"}>
        <Typography variant="subtitle2">Time Left:</Typography>
        <Typography variant="subtitle2" className={"time-left"}>
          {calculateTimeRemaining(mission?.eta_remaining_seconds)}
        </Typography>
      </Typography>
    </Box>
  )
}

export default MapRouteProgressDisplay
