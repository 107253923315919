import "./scss/missionDetails.scss"

const MissionDetails = () => {
  return (
    <div className="mission-details-container" data-testid="mission-details-container">
      <div className="mission-details-container__truck-info-panel">truck stuff goes here</div>
      <div className="mission-details-container__load-details-panel">load details goes here</div>
      <div className="mission-details-container__schedule-panel">schedule stuff goes here</div>
      <div className="mission-details-container__dist-travel-map-panel">distance traveled map stuff goes here</div>
    </div>
  )
}

export default MissionDetails
