import { useMutation, useQueryClient } from "@tanstack/react-query"
import { EndSessionDocument } from "@torc-robotics/vda-sessions-api/graphql/document"
import execute from "../api/SessionsAPIGraphQL"
import { getLogger } from "../../framework/contexts/DataDog"
import useCurrentSession from "../hooks/useCurrentSession"
import { useLoadId } from "../contexts/LoadId"
import { datadogRum } from "@datadog/browser-rum"

const logger = getLogger()

const useEndSessionMutation = () => {
  const queryClient = useQueryClient()
  const session = useCurrentSession()
  const loadId = useLoadId()

  const mutation = useMutation({
    mutationFn: async () => {
      return await execute(EndSessionDocument, { sessionId: session?.sessionId ?? "" })
    },
    onSuccess: /* istanbul ignore next */ async ({ data }) => {
      if (data?.endSession.__typename === "UserNotAuthorizedError") {
        logger.error(data.endSession.message)
        alert(data.endSession.message)
      } else if (data?.endSession.__typename === "SessionNotFoundError") {
        logger.error(data.endSession.message)
      } else if (data?.endSession.__typename === "ActiveSession") {
        logger.debug(`successfully ended session ${loadId.current}`)
        loadId.setCurrent("")
        await queryClient.invalidateQueries({
          queryKey: ["getSessions"],
        })
      } else {
        logger.warn("Unhandled return type for EndSessionMutation: ", data?.endSession)
      }
    },
    onError: (error) => {
      logger.error("Error ending session: ", error)
      alert(error)
    },
    onSettled(data, error, variables, context) {
      datadogRum.addAction("Ending Session", { data: data, variables: variables, error: error })
    },
    retry: false,
  })

  return mutation
}

export default useEndSessionMutation
