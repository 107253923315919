import MissionsAPI from "../api/MissionsAPI"
import { useQuery } from "@tanstack/react-query"
import useMissionQuery from "./useMissionQuery"

const useGetMissionHistoryLog = () => {
  const missionQuery = useMissionQuery()
  const missionsHistoryLogQuery = useQuery({
    queryKey: ["mission-history-log", missionQuery.data?.missions?.[0]?.uuid],
    refetchInterval: 1000,
    refetchIntervalInBackground: true,
    queryFn: /* istanbul ignore next */ async () =>
      await MissionsAPI.v1.getMissionHistory({
        uuid: missionQuery.data?.missions?.[0]?.uuid ?? "",
        logTypes: ["State Transition", "Remote Assistance"],
      }),
    enabled: !!missionQuery.data?.missions?.[0]?.uuid,
  })

  return missionsHistoryLogQuery
}

export default useGetMissionHistoryLog
