import { useQuery } from "@tanstack/react-query"
import MissionsAPI from "../api/MissionsAPI"
import useMissionQuery from "./useMissionQuery"
import RouteAdvisorAPI from "../api/RouteAdvisorAPI"

const useGetMissionRoute = () => {
  const mission = useMissionQuery()

  const missionRoute = useQuery({
    queryKey: ["missionRoute"],
    queryFn: /* istanbul ignore next */ async () =>
      await MissionsAPI.v1.getMissionRoutes({
        missionUuid: mission.data?.missions?.[0]?.uuid ?? "",
        selected: "true",
      }),
    enabled: mission.data?.missions?.[0]?.uuid !== undefined,
  })

  const routeAdvisorRoute = useQuery({
    queryKey: ["routeAdvisor"],
    queryFn: /* istanbul ignore next */ async () =>
      await RouteAdvisorAPI.getRouteById({
        routeId: missionRoute.data?.[0]?.route_id ?? "",
      }),
    enabled: !!missionRoute.data?.[0]?.route_id,
  })

  return {
    missionRoute,
    routeAdvisorRoute,
  }
}

export default useGetMissionRoute
